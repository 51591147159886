initTooltips($('.tooltip-always'), initTooltips.opts(), true);

(function () {
	Numbers.formatNodes();
})();

(function () {
	const totalCap = $('span[data-bind="total.cap"]'), totalVol24 = $('span[data-bind="total.vol24"]');
	AppWs.subscribeToTotal(function (arg) {
		const code = arg[0], val = arg[1];
		if (code === 'CAP.TOTAL') {
			totalCap.text(App.formatters.hugo(val));
		}
		if (code === 'TOTAL.VOL.24H') {
			totalVol24.text(App.formatters.hugo(val));
		}
	});
})();

(function () {
	$('#header-navbar').find('.form-control-with-search-icon > .form-control')
		.focus(function () {
			$(this).parent().addClass('focus');
		})
		.blur(function () {
			$(this).parent().removeClass('focus');
		});

	function updateToggleMessage(el) {
		el.text(el.data('btn-toggle-' + el.data("btn-toggle") + '-message'));
	}

	$('a[data-btn-toggle]').each(function () {
		const el = $(this);
		updateToggleMessage(el);
		el.addClass('pointer');
		el.click(function () {
			const current = !!el.data('btn-toggle');
			const actionHref = el.data('btn-toggle-' + current + '-href');
			if (typeof actionHref === 'string') {
				$.get(actionHref)
					.done(function (a) {
						el.data('btn-toggle', !!a);
						updateToggleMessage(el);
					});
			}
		});
	});
})();

(function () {
	hugos.refresh();
})();

